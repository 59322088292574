
import Layout from "../components/layout"
import React from "react"


export default () => {

  return(
    <Layout>
      <div className="mc-page container-fluid">
        <div className="row">
          <div className={"col-md-12"}>
            <h3>PRIVACY POLICY</h3>
            <p>
              Cashback Medical has created this privacy statement to demonstrate our commitment to your privacy. We respect your privacy and will not sell, lease or distribute your information gathered from the IP address.
            </p>
            <p>
              Effective Date: January 5, 2024
            </p>
            <h3>SCOPE OF THIS POLICY</h3>
            <p>
              Cashback Medical has created this privacy policy to describe how we collect, use, maintain, share, and protect your personal information when you use our website, CashbackMedical.com, or any payment portal, hosted as a subdomain under CashbackMedical.com. This privacy policy applies to information collected when you visit our website or payment portal. It does not apply to information collected in any other way, including information collected offline. If you are a California resident, please see additional information in our California Privacy Notice.
            </p>
            <h3>CHILDREN’S PRIVACY</h3>
            <p>
              The Cashback Medical website located at CashbackMedical.com (its “website”) or any subdomain is not intended for individuals less than the age of eighteen and Cashback Medical does not knowingly collect data relating to individuals less than the age of eighteen.
            </p>
            <h3>THE FEDERAL FAIR DEBT COLLECTION PRACTICES ACT</h3>
            <p>
              Nothing in this privacy statement is intended to contradict your rights under the Fair Debt Collection Practices Act. Cashback Medical will not disclose any information to third parties that is otherwise prohibited by the FDCPA.
            </p>
            <h3>CHANGES TO THIS POLICY</h3>
            <p>
              We may modify this privacy policy at any time. If we do, we will post the revised version here. You should periodically check here for the most up-to-date version of this privacy policy. Any changes that affect how we use or disclose personal information will not be retroactively applied.
            </p>
            <h3>INFORMATION AUTOMATICALLY COLLECTED</h3>
            <p>
              When you visit the website, we automatically collect certain information, like your IP address, browser type and operating system, the domain from which you came, the page you are viewing, and the date and time of your visit (“usage data”).
              We use this information for a number of purposes, such as investigating security incidents, running analytics to understand usage, and for compliance with security standards.
            </p>
            <h3>PERSONAL INFORMATION</h3>
            <p>
              We do not collect personal information when you visit our website unless you choose to provide it to us. You may elect to share personal information with us by filling out the information on our Contact page, such as name, address, phone number, email address, and any other personal information you choose to provide. You may also elect to share personal information with us by emailing us using the links on our website.  We may have collected the following categories of personal information from consumers within the last 12 months:
            </p>
            INSERT TABLE HERE


            <h3>HOW WE USE YOUR INFORMATION</h3>
            <p>
              Personal information is collected solely for the purpose of debt recovery in a lawful manner and remains part of our records until we determine the information is no longer needed, or we are required by law to delete such information. We will collect the minimum amount of data necessary to effectuate debt collection. Your personal information may be used to fulfill or meet the reason you provided such information. For instance, we use information you send to us to respond to your inquiries or comments.
              Your personal information will also be used to perform services on behalf of the business or service provider, including maintaining or servicing accounts, providing customer service, verifying customer information, processing payments, providing analytic services, or providing similar services.
              We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes.
            </p>

            <h3>SALE OF PERSONAL INFORMATION</h3>
            <p>
              We do not sell and will not sell your personal information.
            </p>

            <h3>SUBSCRIBER MOBILE NUMBERS</h3>
            <p>
              No mobile information, including text messaging originator opt-in data and consent, will be shared with third parties/affiliates for marketing/promotional purposes.
            </p>

            <h3>HOW WE COLLECT YOUR INFORMATION</h3>

            <p>
              We collect most of this personal information directly from our clients for whom we provide services to, as well as from you by telephone, written correspondence through the mail, email or fax, by viewing public social media/network pages, or other information available online. However, we may also collect information:
            </p>
            <ul>
              <li>From publicly accessible sources (e.g., property records or court records);</li>
              <li>From your transactions with us;</li>
              <li>From our service providers (e.g., letter vendor, location vendors, payment processing vendors, call analytics vendor, and/or electronic signature service provider);</li>
              <li>Directly from a third party;</li>
              <li>Consumer reporting agencies (CRAs);</li>
              <li>From a third party with your consent (e.g., your authorized representative and/or attorney);</li>
              <li>From activity on our website;</li>
            </ul>

            <h3>GOOGLE ANALYTICS</h3>
            <p>
              We use a tool called “Google Analytics” to collect information about use of this site. Google Analytics uses cookies and similar technologies to collect information such as how often users visit this site, what pages they visit when they do so, and what other sites they used prior to coming to this site. We do not combine the information collected through use of Google Analytics with personal information.
              For information on how Google Analytics uses data and how to control information sent to Google, visit www.google.com/policies/privacy/partners/.
              We use the information we get from Google Analytics to understand how our website is used and make improvements.
            </p>

            <h3>COOKIES</h3>
            <p>
              We use cookies to collect website usage data, to improve the site and our services, as well as your experience with the site. Cookies are pieces of information generated by a web server and stored in your computer.  Cashback Medical only uses session cookies. A session cookie is deleted when you close the browser. You can control cookies through your browser settings and other tools.
            </p>

            <h3>THIRD-PARTY LINKS AND WEBSITES</h3>
            <p>You may be able to access third-party sites by clicking links on our site. We are not responsible for the privacy policies or practices of any third-party sites, and if you visit those websites, we encourage you to review their respective privacy policies.</p>

            <h3>CHOICE & ONLINE TRACKING</h3>
            <p>
              As a user of our website, you control whether to send us personal information by using our Contact form.
              Cashback Medical does not track your online activities over time and across third party websites.
            </p>
            <h3>INFORMATION SHARING AND DISCLOSURE</h3>
            <p>Cashback Medical may share personal information it collects with its employees who need to know that information to provide services to you. Except as provided below, Cashback Medical does not share or disclose any personal information with any company or marketing group external to Cashback Medical. Cashback Medical may share your personal information with third parties to the extent it is reasonably necessary to manage or service your account, determine location, process payment, fulfill a transaction, provide customer service, or as otherwise authorized by law.
              Further, Cashback Medical may disclose personal information (i) to another entity with which Cashback Medical enters or reasonably may enter into a corporate transaction, such as, for example, a merger, consolidation, acquisition, or asset purchase, (ii) to a third party pursuant to a subpoena, court order, or other form of legal process or in response to a request by or on behalf of any local, state, federal, or other government agency, department, or body, whether or not pursuant to a subpoena, court order, or other form of legal process, or in connection with litigation brought against, or on behalf of, Cashback Medical, where appropriate, (iii) to a third party if determined by Cashback Medical in its sole judgment that such disclosure is appropriate to protect the life, health, or property of Cashback Medical or any other person or entity, all in compliance with applicable law, (iv) to third parties as authorized or designated by you, or (v) to conduct any other legitimate business activity not otherwise prohibited by law. The foregoing is not intended to obviate or displace any legal obligations or duties applicable to Cashback Medical.</p>

            <h3>SHARING YOUR INFORMATION WITH CONSUMER REPORTING AGENCIES</h3>
            <p>
              Cashback Medical does not share any information with Consumer Reporting Agencies
            </p>

            <h3>HOW LONG WE KEEP YOUR INFORMATION</h3>
            <p>
              Cashback Medical will keep your Personal Information while you have an account placed with us and may retain your personal data for up to 10 years, or as otherwise required by law or pursuant to contractual obligation. Cashback Medical thereafter deletes or anonymizes such information in accordance with applicable laws.
            </p>

            <h3>ACCESS AND CORRECTION OF PERSONAL INFORMATION</h3>
            <p>
              You may access and update personal information you have provided through our website either by contacting us at the address or telephone number listed below.
            </p>
            <p>
              Cashback Medical <br/>
              9173 Bay Point Dr.<br/>
              Orlando, FL 32819<br/>
              Phone: 1-407-259-2969
            </p>
            <h3>SECURITY AND CONFIDENTIALITY</h3>
            <p>
              Cashback Medical strives to protect personal information that we collect, maintain, and disclose through the use of reasonable administrative, physical, and technical safeguards. Personal information submitted online is transmitted through transport layer security (TLS), which is a protocol for establishing a secure connection for transmission. Our security program is designed to: (1) ensure the security and confidentiality of personal information, (2) protect against any anticipated threats or hazards to the security or integrity of the information, and (3) protect against unauthorized access, alteration, and unlawful disclosure of the information. We dispose of such information by taking reasonable measures to protect against unauthorized access to or use of the information.
            </p>
            <h3>POINT OF CONTACT</h3>
            <p>
              If you have any questions or comments about Cashback Medical privacy policy or our privacy practices, please contact us at:
            </p>
            <p>
              Cashback Medical <br/>
              Attn: Consumer Assistance<br/>
              9173 Bay Point Dr<br/>
              Orlando, FL	32819<br/>
              Phone: 1-407-259-2969<br/>
              experience@CashbackMedical.com
            </p>

          </div>

        </div>
      </div>
    </Layout>
    )

}